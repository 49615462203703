<template>
  <el-dialog
    class="consultation-room-dialog"
    :title="`${preTitle}羽毛球场地`"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="cancel"
    width="520px"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
      <el-form-item label="校区" prop="campusId">
        <el-radio-group v-model="formData.campusId">
          <el-radio-button v-for="{campusName, id} in campusList" :key="id" :label="id">{{ campusName }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="场地名称" prop="name">
        <el-input v-model.trim="formData.name" />
      </el-form-item>
<!--      <el-form-item label="位置" prop="address">-->
<!--        <el-input v-model.trim="formData.address" />-->
<!--      </el-form-item>-->
      <el-form-item label="状态" prop="enable">
        <el-switch
          v-model="formData.enable"
          active-color="var(--color-success)"
          :active-value="1"
          active-text="启用"
          inactive-text="禁用"
          :inactive-value="0"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('羽毛球场地',saveBadmintonCourtApi)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { getBadmintonCourtByIdApi, saveBadmintonCourtApi } from '@/api/student-manage/badminton-court-api'

export default {
  name: 'badmintonCourtDialog',
  mixins: [dialog],
  props: {
    addData: {
      type: Object
    }
  },
  data() {
    return {
      campusList: [], // 校区list
      formData: {
        id: '',
        campusId: '',
        enable: 1,
        name: '',
        remarks: ''
      },
      rules: {
        campusId: {
          required: true,
          message: '请选择',
          trigger: 'blur'
        },
        name: [{
          required: true,
          message: '请输入羽毛球场地名',
          trigger: 'blur'
        },
          this.$store.state.commonValidate.limit30WordsObj]
      }
    }
  },
  mounted() {
    this.getCampusList()
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getBadmintonCourtByIdApi)
      }
    })
  },
  methods: {
    saveBadmintonCourtApi,
    // 获取校区
    async getCampusList() {
      try {
        const res = await this.$http.getCampusListQuery({})
        this.campusList = res.data
      } catch (e) {
      }
    }
  }
}
</script>
