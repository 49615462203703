<template>
  <div>
    <el-dialog
      v-loading="loading"
      class="badminton-schedule-dialog"
      :title="`${preTitle}羽毛球场排期`"
      :visible.sync="show"
      :close-on-click-modal="false"
      :before-close="cancel"
      width="920px"
    >
      <el-form ref="form" :model="formData" :rules="rules" label-width="120px" size="small">
        <el-form-item v-if="parentData.name" label="位置">
          <b>{{ parentData.campusName }}{{ parentData.name }}</b>
        </el-form-item>
        <el-row v-if="!parentData.name">
          <el-col :span="12">
            <el-form-item label="校区">
              <el-select ref="campusRef" v-model="campusId"
                         filterable
                         @change="handleCourtList"
                         size="small">
                <el-option v-for="{ name, id,children } in campusList" :disabled="!children.length" :key="id"
                           :label="name" :value="id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-button class="ml-1" size="small" @click="()=>visibleRuleDialog=true"
            >校区规则管理
            </el-button>
          </el-col>
        </el-row>
        <el-form-item label="规则">
          <el-descriptions v-if="campusRule.name" :column="2" border>
            <el-descriptions-item label-class-name="descriptions-item__label" label="规则名">
              {{ campusRule.name }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="descriptions-item__label" label="状态">
              <el-tag :type="campusRule.offFlag?'info':'success'">{{ campusRule.offFlag ? '禁用' : '启用' }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="descriptions-item__label" label="时间间隔">
              {{ campusRule.orderInterval }}分钟
            </el-descriptions-item>
            <el-descriptions-item label-class-name="descriptions-item__label" label="预约时长上限">
              {{ campusRule.dayOrderMax }}分钟
            </el-descriptions-item>
            <el-descriptions-item label-class-name="descriptions-item__label" label="备注" :span="2">
              {{ campusRule.remarks }}
            </el-descriptions-item>
          </el-descriptions>
          <el-empty description="请选择校区规则" v-else></el-empty>
        </el-form-item>
        <template v-if="campusRule.orderInterval">
          <el-card shadow="never">
            <div class="tip">
              1.根据设定的时间段和间隔（{{ campusRule.orderInterval
              }}分钟），数据库将自动生成一系列可预约时间窗口。例如设置了60分钟间隔和9:00至12:00的时间段，将会在上传数据库后细化为9:00-10:00，10:00-11:00，以及11:00-12:00，以满足预约需求。
              <div>
                2.当需要在特定时间段内禁止预约服务时，比如在午休期间，请实际可用时段进行合理划分，例如从8:30至12:30，和14:20至22:20，以确保服务的连续性和用户预约的灵活性。
              </div>
            </div>
            <el-row>
              <el-col :span="14">
                <el-form-item label="可预约时间段" prop="keyword">
                  <el-row>
                    <el-col :span="11">
                      <el-time-select
                        placeholder="起始时间"
                        v-model="startTime"
                        format="HH:mm"
                        value-format="HH:mm"
                        :picker-options="{
                              start: '05:30',
                              step: comptStep,
                              end: '22:30',
                            }">
                      </el-time-select>
                    </el-col>
                    <el-col :span="1">
                      -
                    </el-col>
                    <el-col :span="10">
                      <el-time-select
                        placeholder="结束时间"
                        v-model="endTime"
                        format="HH:mm"
                        value-format="HH:mm"
                        :picker-options="{
                              start: '06:00',
                              step: comptStep,
                              end: '22:30',
                              minTime: startTime
                            }" />
                    </el-col>
                    <el-col :span="2">
                      <el-button :disabled="!(startTime&&endTime)" class="ml-1" icon="el-icon-plus" type="primary" plain
                                 size="small" @click="addTimeStamp">
                        生成时间段
                      </el-button>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="可预约时间段">
              <template v-if="timeStampList.length">
                <el-tag v-for="(item,index) in timeStampList" class="mb-1 ml-1 mr-1" :key="index" closable
                        @close="delTimeStamp(item)">
                  {{ item.startTimeStr }}- {{ item.endTimeStr }}
                </el-tag>
              </template>
            </el-form-item>
            <el-form-item label="生效周" prop="weekDayNameJson">
              <el-checkbox v-model="checkAll" @change="getCheckAll">全选</el-checkbox>
              <el-checkbox-group v-model="weekDays" @change="checkWeekDays">
                <el-checkbox border v-for="num in 7" class="ml-1 mr-1 mb-1" :key="num" :label="num">
                  周{{ $store.state.systemParam.weekList[num]
                  }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="">
              <el-button v-show="isEditing" :disabled="!(weekDays.length&&timeStampList.length)" class="ml-1"
                         type="primary"
                         size="small" @click="editFinish">
                编辑完成
              </el-button>
              <el-button v-show="!isEditing" type="primary" :disabled="!(weekDays.length&&timeStampList.length)" plain
                         @click="addWeekTimeStamp">生成周次间隔
              </el-button>
            </el-form-item>
          </el-card>
          <el-divider>已生成时间段</el-divider>
          <el-form-item v-if="formData.badmintonRoomSchedules.length" label-width="0">
            <el-card v-for="(item,index) in formData.badmintonRoomSchedules" class="mt-1" :key="index" shadow="hover">
              <el-button-group class="card-btns">
                <el-button class="card-del-button" :disabled="isEditing" type="primary" plain icon="el-icon-copy-document"
                           @click="edit(item,index)">编辑
                </el-button>
                <el-button class="card-del-button" :disabled="isEditing" type="danger" plain icon="el-icon-close"
                           @click="delWeekTimeStamp(index)"></el-button>
              </el-button-group>
              <div><label for="" style="width: 100px;display: inline-block;text-align:right;">周：</label>
                <el-tag v-for="(subItem,subIndex) in JSON.parse(item.weekDayNameJson)" class="ml-1 mb-1 mr-1"
                        :key="subIndex">
                  周{{ $store.state.systemParam.weekList[+subItem] }}
                </el-tag>
              </div>
              <div><label for="" style="width: 100px;display: inline-block;text-align:right;">可预订时间段：</label>
                <el-tag v-for="(thirdItem,thirdIndex) in item.badmintonRoomScheduleTimeSaves" class="ml-1 mb-1 mr-1"
                        :key="thirdIndex">
                  {{ thirdItem.startTimeStr }} - {{ thirdItem.endTimeStr }}
                </el-tag>
              </div>
            </el-card>
          </el-form-item>
          <el-empty v-if="!formData.badmintonRoomSchedules.length" description="暂无时间段" />
          <!--  编辑时不显示球场list, 在校区显示-->
          <template v-if="!parentData.name">
            <el-form-item label="羽毛球场" v-if="courtList.length">
              <el-checkbox v-model="courtCheckAll" @change="getCheckAllCourts">全选</el-checkbox>
              <el-checkbox-group v-model="formData.badmintonRoomIds" @change="checkCourts">
                <el-checkbox class="mb-1 ml-1" border v-for="item in courtList" :key="item.id" :label="item.id">
                  {{ item.name
                  }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <div v-if="!courtList.length" class="warning-tip">
              该校区暂无羽毛球场，请先添加羽毛球场。
            </div>
          </template>
        </template>
      </el-form>
      <template #footer>
        <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
        <!--        :disabled="!(formData.badmintonRoomSchedules.length&&formData.badmintonRoomIds.length)"-->
        <el-button :disabled="!courtList.length" type="primary" @click="save">批量生成
        </el-button>
      </template>
    </el-dialog>
    <!--  校区排期规则  -->
    <badminton-rule-dialog :visible.sync="visibleRuleDialog" v-if="visibleRuleDialog" />
  </div>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { saveBadmintonModelApi } from '@/api/student-manage/badminton-schedule-api'
import { listBadmintonCourtApi } from '@/api/student-manage/badminton-court-api'
import { throttle } from 'lodash'
import { getBadmintonRuleByCampusIdApi } from '@/api/student-manage/badminton-rule-api'
import BadmintonRuleDialog from '@/views/pages/studentManage/badminton-court/components/badmintonRuleDialog.vue'

export default {
  name: 'BadmintonScheduleDialog',
  components: {
    BadmintonRuleDialog
  },
  mixins: [dialog],
  props: {
    departmentList: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      visibleRuleDialog: false,
      isEditing: undefined,
      startTime: '',
      endTime: '',
      loading: false,
      campusList: [],
      campusId: '', // 校区id
      timeStampList: [], // 时间间隔列表
      weekTimeStampList: [], // 周次间隔
      weekDays: [], // 勾选的周几
      campusRule: {}, // 校区规则
      parentData: {}, // 父级row数据
      courtList: [], // 场地list
      courtCheckAll: false, // 羽毛球场勾选全部
      checkAll: false,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      formData: {
        badmintonRoomIds: [],
        badmintonRoomSchedules: []
      },
      rules: {
        campusId: {
          required: true,
          message: '请选择校区',
          trigger: 'blur'
        }
      }
    }
  },
  async mounted() {
    await this.getCampusList()
    await this.$nextTick()
    if (this.parentData?.id) {
      await this.setDataByParentData()
    } else {
      this.setInitData()
    }
  },
  computed: {
    comptStep() {
      if (this.campusRule.orderInterval % 5 === 0) {
        return '00:05'
      } else {
        return '00:01'
      }
    }
  },
  methods: {
    // 根据父级row生成编辑数据
    async setDataByParentData() {
      this.campusId = this.parentData.campusId
      // eslint-disable-next-line no-unused-expressions
      this.formData.badmintonRoomSchedules = this.parentData.badmintonRoomSchedules?.map(item => {
        const badmintonRoomScheduleTimeSaves = []
        item.badmintonRoomScheduleTimeGets.length && item.badmintonRoomScheduleTimeGets.forEach(({
                                                                                                   startTimeStr,
                                                                                                   endTimeStr
                                                                                                 }) => {
          badmintonRoomScheduleTimeSaves.push({
            startTimeStr,
            endTimeStr
          })
        })
        return {
          weekDayNameJson: item.weekDayNameJson,
          badmintonRoomScheduleTimeSaves
        }
      })
      this.formData.badmintonRoomIds = [this.parentData.id]
      await this.handleCourtList(this.parentData.campusId)
      this.setInitData()
    },
    // 获取校区
    async getCampusList() {
      try {
        const res = await this.$http.getCampusListQuery({})
        const _promises = []
        const result = []
        res.data.forEach(campus => {
          _promises.push(() => this.getCourtList(campus, result))
        })
        await Promise.all(_promises.map(fn => fn()))
        this.campusList = result
      } catch (e) {
      }
    },
    // 场地list
    async getCourtList(campus, arr) {
      try {
        const res = await listBadmintonCourtApi({ campusId: campus.id })
        // 获取每个校区的场地list
        arr.push({
          id: campus.id,
          name: campus.campusName,
          children: res.data
        })
      } catch (e) {
        console.error(e)
      }
    },
    // 根据校区获取场地list
    async handleCourtList(val) {
      this.courtList = this.campusList.find(item => item.id === val)?.children || []
      try {
        const { data } = await getBadmintonRuleByCampusIdApi(val)
        this.campusRule = data
      } catch (e) {
        console.error(e)
      }
    },
    addTimeStamp(tag) {
      if (tag) {
        const start = this.$moment(this.startTime, 'HH:mm')
        const end = this.$moment(this.endTime, 'HH:mm')
        const diff = (end.diff(start, 'm'))
        if (diff < this.campusRule.orderInterval) {
          this.$message.warning('时间间隔不能小于' + this.campusRule.orderInterval + '分钟')
          return
        } else if (diff % this.campusRule.orderInterval > 0) {
          this.$message.warning('时间间隔必须能被' + this.campusRule.orderInterval + '整除！')
          return
        }
        const result = {
          startTimeStr: this.startTime,
          endTimeStr: this.endTime
        }
        const hasTimeStamp = this.timeStampList.find(item => item.startTimeStr === tag.startTimeStr && item.endTimeStr === tag.endTimeStr)
        if (hasTimeStamp) {
          this.$message.warning('该时间段已存在')
        } else {
          this.timeStampList.push(result)
          this.startTime = ''
          this.endTime = ''
        }
      }
    },
    delTimeStamp(tag) {
      this.timeStampList = this.timeStampList.filter(item => item.startTimeStr !== tag.startTimeStr && item.endTimeStr !== tag.endTimeStr)
    },
    // 开始编辑
    edit(target, index) {
      this.timeStampList = target.badmintonRoomScheduleTimeSaves
      this.weekDays = JSON.parse(target.weekDayNameJson) || []
      this.isEditing = index + 1
    },
    // 编辑完成
    editFinish() {
      const result = {
        badmintonRoomScheduleTimeSaves: this.timeStampList,
        weekDayNameJson: JSON.stringify(this.weekDays)
      }
      this.formData.badmintonRoomSchedules.splice(this.isEditing - 1, 1, result)
      this.timeStampList = []
      this.weekDays = []
      this.checkAll = false
      this.isEditing = undefined
    },
    // 新增
    addWeekTimeStamp() {
      const result = {
        badmintonRoomScheduleTimeSaves: this.timeStampList,
        weekDayNameJson: JSON.stringify(this.weekDays)
      }
      this.formData.badmintonRoomSchedules.push(result)
      this.timeStampList = []
      this.weekDays = []
      this.checkAll = false
    },
    // 删除
    delWeekTimeStamp(index) {
      this.$confirm('是否确认删除该预约周期？', '删除确认').then(() => {
        // this.formData.badmintonRoomSchedules = this.formData.badmintonRoomSchedules.filter((row) => !isEqual(row, target))
        this.formData.badmintonRoomSchedules.splice(index, 1)
      })
    },
    // 根据_index参数
    getCheckAll(val) {
      if (val) {
        this.weekDays = [1, 2, 3, 4, 5, 6, 7]
      } else {
        this.weekDays = []
      }
      // this.formData.weekDayNameJson = JSON.stringify(this.weekDays)
    },
    // 根据勾选的数量判断是否全选和全不选状态，同时把数据同步到 weekDayNameJson
    checkWeekDays(val) {
      val = val.sort()
      this.checkAll = val.length === 7
      this.formData.weekDayNameJson = JSON.stringify(val)
    },
    // 羽毛球场全选
    getCheckAllCourts(val) {
      this.formData.badmintonRoomIds = []
      if (val) {
        this.formData.badmintonRoomIds = this.courtList.map(item => item.id)
      }
    },
    // 根据勾选的数量判断是否全选和全不选状态，同时把数据同步到 weekDayNameJson
    checkCourts(val) {
      val = val.sort()
      this.courtCheckAll = val.length === this.courtList.length
    },
    save: throttle(function() {
      this.$confirm(`此操作将${this.parentData.id ? '修改' : '新增'}羽毛球排期表, 是否继续?`, '提示').then(async () => {
        this.loading = true
        try {
          await saveBadmintonModelApi(this.formData)
          this.$message.success('保存成功！')
          this.closed()
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      }).catch()
    }, 1000)
  }
}
</script>
<style lang="scss">
.badminton-schedule-dialog {
  .el-dialog {
    margin-top: 20px !important;
  }

  .el-card {
    position: relative;

    .card-btns {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
</style>
