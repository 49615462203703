import request from '@/service/request'
import store from '@/store/index'
// const _permission = store.getters['login/getPermission']
const isAdmin = store.state.login.isAdmin
/* 羽毛球场地管理，分页 管理员 查看所有
*  接口地址准确 因为后端颠颠的，乱来 ，导致羽毛球几个page接口命名不规范
* @params
* */
export const pageBadmintonCourtApi = (params) => {
  // 管理员查看所有数据
  // 否则按管辖球场获取数据
  const url = isAdmin ? '/stu/badmintonRoom/pageManager' : '/stu/badmintonRoom/page'
  return request.post(url, params)
}

/* 羽毛球场地管理，list
*  接口地址准确 因为后端颠颠的，乱来 ，导致羽毛球几个page接口命名不规范
* @params
* */
export const listBadmintonCourtApi = (params = {}) => {
  // 管理员查看所有数据
  // 否则按管辖球场获取数据
  const url = isAdmin ? '/stu/badmintonRoom/listManager' : '/stu/badmintonRoom/list'
  return request.post(url, params)
}

/* 羽毛球场地管理，根据id获取
* @params
* */
export const getBadmintonCourtByIdApi = (id) => {
  return request.post(`/stu/badmintonRoom/get/${id}`)
}
/* 羽毛球场地管理，删除
* @params
* */
export const delBadmintonCourtByIdApi = (id) => {
  return request.post(`/stu/badmintonRoom/del/${id}`)
}

/* 羽毛球场地管理，保存
* @params
* */
export const saveBadmintonCourtApi = (params) => {
  return request.post('/stu/badmintonRoom/save', params)
}

/* 羽毛球场地管理，保存
* @params
* */
export const batchBindBadmintonCourtByUserIdApi = (params) => {
  return request.post('/stu/badmintonRoom/manager/saveBatch', params)
}

/* 羽毛球场地管理，批量绑定管理员
* @params {string} campusId 校区id
* @params {array} userIds 用户id list
* @params {number} userType 用户类型： 2-学生 3-教职工
* */
export const batchBindManagersByCourtApi = (params) => {
  return request.post('/stu/badmintonManager/bindBatch', params)
}
/* 羽毛球场地管理，根据校区id获取学生核销员list
* @params {string} campusId
* */
export const listCourtStuApi = (params) => {
  return request.post('/stu/badmintonManager/listByStu', params)
}
/* 羽毛球场地管理，根据校区id获取教职工核销员list
* @params {string} campusId
* */
export const listCourtTeacherApi = (params) => {
  return request.post('/stu/badmintonManager/list', params)
}

/* 羽毛球场地管理，管理员list
* @params
* */
export const listBadmintonAdminApi = (params = {}) => {
  return request.post('/stu/badmintonRoom/manager/list', params)
}
