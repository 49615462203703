import request from '@/service/request'

/* 羽毛球排期规则，分页
* @params
* */
export const pageBadmintonRuleApi = (params) => {
  return request.post('/stu/badmintonRule/page', params)
}

/* 羽毛球排期规则，list
* @params
* */
export const listBadmintonRuleApi = (params = {}) => {
  return request.post('/stu/badmintonRule/list', params)
}

/* 羽毛球排期规则，根据id获取
* @params
* */
export const getBadmintonRuleByIdApi = (id) => {
  return request.post(`/stu/badmintonRule/get/${id}`)
}

/* 羽毛球排期规则，根据校区id获取
* @params
* */
export const getBadmintonRuleByCampusIdApi = (CampusId) => {
  return request.post(`/stu/badmintonRule/getByCampusId/${CampusId}`)
}
/* 羽毛球排期规则，删除
* @params
* */
export const delBadmintonRuleByIdApi = (id) => {
  return request.post(`/stu/badmintonRule/del/${id}`)
}

/* 羽毛球排期规则，保存
* @params
* */
export const saveBadmintonRuleApi = (params) => {
  return request.post('/stu/badmintonRule/save', params)
}
