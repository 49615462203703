<!--  心理咨询室  -->
<template>
  <table-view
    class="badminton-court"
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        ref="searchFormRef"
        :queryInfo.sync="queryInfo"
        :tool-list="['campus','keyword']"
        :filter-keys="['scheduleFlag']"
        @on-search="renderTable(1)">
        <el-form-item label="状态" label-width="50px">
          <el-select v-model="queryInfo.enable" size="small">
            <el-option :value="1" label="启用" />
            <el-option :value="0" label="禁用" />
          </el-select>
        </el-form-item>
      </view-search-form>
      <div class="header-button">
        <div>
          <el-descriptions :column="1" border size="mini">
            <el-descriptions-item label-class-name="descriptions-item__label" label="教职工核销员">
              {{ writeOffers.teacherList.map(item => `${item.realName}(${item.phone})`).join(',') }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="descriptions-item__label" label="学生核销员">
              {{ writeOffers.studentList.map(item => `${item.stuName}(${item.fixedPhone})`).join(',') }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div style="white-space: nowrap">
<!--          <el-tooltip class="item" effect="dark" content="" placement="top-start">-->
            <el-button v-permission="['badminton:court:add']" type="primary" plain
                       size="small"
                       @click="()=>visibleBindDialog=true"
            >绑定校区管理员
            </el-button>
<!--          </el-tooltip>-->
          <el-button v-permission="['badminton:rule:edit']" class="ml-1" size="small"
                     @click="()=>visibleRuleDialog=true"
          >校区规则管理
          </el-button>
          <el-tooltip class="item" effect="dark" content="存在羽毛球场地才能新增排期模板" placement="top-start">
            <el-button v-permission="['badminton:court:add']" :disabled="!tableData.length" type="primary" plain
                       size="small" @click="()=>visibleScheduleDialog=true"
            >新增排期模板
            </el-button>
          </el-tooltip>
          <el-button v-permission="['badminton:court:add']" type="primary" size="small" @click="showDialog()"
          >新增羽毛球场地
          </el-button>
        </div>
      </div>
    </template>
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column prop="name" label="羽毛球场地" min-width="200" />
      <el-table-column prop="campusName" label="校区" width="120" align="center" />
      <el-table-column label="管理员" min-width="260">
        <template v-slot="{row}">
          <template v-if="row.managers&&row.managers.length">
            <el-tag v-for="item in row.managers" :key="item.id" class="ml-1 mb-1">{{ item.realName }}</el-tag>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="180" align="center">
        <template v-slot="{row}">
          <el-switch
            v-if="isEditor"
            v-model="row.enable"
            @change="handleBatSwitch(row)"
            active-color="var(--color-success)"
            :active-value="1"
            active-text="启用"
            inactive-text="禁用"
            :inactive-value="0"
          />
          <template v-else>
            <el-tag v-if="row.enable" type="success">启用</el-tag>
            <el-tag v-else type="info">禁用</el-tag>
          </template>
        </template>
      </el-table-column>
      <el-table-column v-permission="['badminton:court:edit']" label="操作" width="350" fixed="right">
        <template v-slot="{row}">
          <el-button v-permission="['badminton:court:edit']" type="primary" plain size="mini"
                     @click="showDialog(row.id)">编辑
          </el-button>
          <el-button v-if="isEditor" v-show="row.badmintonRoomSchedules.length" type="primary"
                     size="small"
                     @click="showScheduleDialog(row)"
          >排期模板
          </el-button>
          <el-button v-permission="['badminton:court:edit']" type="primary" size="small" @click="showBindDialog(row)"
          >绑定/解绑管理员
          </el-button>
          <el-button v-permission="['badminton:court:del']"
                     size="mini"
                     type="danger"
                     @click="del(delBadmintonCourtByIdApi, row.id,`羽毛球场地-${row.name}`)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <bind-admin-dialog
      :visible.sync="visibleBindDialog"
      v-if="visibleBindDialog"
      :campus-id="queryInfo.campusId"
      @on-close="renderTable" />
    <!--    批量绑定 -->
    <badminton-court-dialog ref="dialogRef"
                            :visible.sync="visibleDialog"
                            v-if="visibleDialog"
                            @on-close="renderTable" />
    <!--  table row 内编辑管理员  -->
    <single-bind-admin-dialog ref="dialogSingleBindRef"
                              :visible.sync="visibleSingleBindDialog"
                              v-if="visibleSingleBindDialog"
                              :manager-list="managerList"
                              @on-close="renderTable" />
    <!--  排班表  -->
    <badminton-schedule-dialog ref="dialogScheduleRef"
                               :visible.sync="visibleScheduleDialog"
                               v-if="visibleScheduleDialog"
                               @on-close="renderTable" />
    <!--  校区排期规则  -->
    <badminton-rule-dialog :visible.sync="visibleRuleDialog" v-if="visibleRuleDialog" />
  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import {
  delBadmintonCourtByIdApi, listCourtStuApi, listCourtTeacherApi,
  pageBadmintonCourtApi,
  saveBadmintonCourtApi
} from '@/api/student-manage/badminton-court-api'
import BadmintonCourtDialog from '@/views/pages/studentManage/badminton-court/components/badmintonCourtDialog.vue'
import BindAdminDialog from '@/views/pages/studentManage/badminton-court/components/bindAdminDialog.vue'
import SingleBindAdminDialog from '@/views/pages/studentManage/badminton-court/components/singleBindAdminDialog.vue'
import BadmintonScheduleDialog from '@/views/pages/studentManage/badminton-court/components/badmintonScheduleDialog.vue'
import { cloneDeep } from 'lodash'
import BadmintonRuleDialog from '@/views/pages/studentManage/badminton-court/components/badmintonRuleDialog.vue'

export default {
  name: 'badmintonCourt',
  mixins: [tableView],
  components: {
    BadmintonRuleDialog,
    BadmintonScheduleDialog,
    SingleBindAdminDialog,
    BindAdminDialog,
    BadmintonCourtDialog
  },
  data() {
    return {
      isEditor: false,
      visibleRuleDialog: false,
      visibleScheduleDialog: false, // 新增排期模板
      visibleBindDialog: false, // 绑定管理员(批量)
      visibleSingleBindDialog: false, // 绑定管理员（按球场）
      managerList: [], // 羽毛球管理员list
      queryInfo: {
        scheduleFlag: 1, // 用于回显排班表字段，没有会不显示 badmintonRoomSchedules里的数据
        campusId: null,
        enable: null,
        userId: null
      },
      gradeYearList: [],
      writeOffers: {
        teacherList: [],
        studentList: []
      }
    }
  },
  watch: {
    'queryInfo.campusId'(val) {
      this.getManagesList(val)
    }
  },
  async mounted() {
    this.isEditor = this.$store.state.login.permissionTable.includes('badminton:court:edit')
    // await this.getManagerList()
  },
  methods: {
    delBadmintonCourtByIdApi, // 删除api
    async renderTable(pageNum) {
      await this.getTableData(pageBadmintonCourtApi, pageNum)
    },
    // 启用、禁用
    handleBatSwitch(row) {
      const msg = row.enable ? '启用' : '禁用'
      this.$confirm(`是否确认修改${row.name}的状态为${msg}?`).then(async () => {
        try {
          await saveBadmintonCourtApi(row)
          this.$message.success(`${row.name}已${msg}!`)
        } catch (e) {
        }
      }).catch(() => {
        row.enable = row.enable ? 0 : 1
        this.$message.info('已取消')
      })
    },
    // 根据校区获取教师、学生核销员
    async getManagesList(campusId) {
      try {
        // 学生
        const res = await listCourtStuApi({
          campusId
        })
        this.writeOffers.studentList = res.data || []
        // 教师
        const res2 = await listCourtTeacherApi({
          campusId
        })
        this.writeOffers.teacherList = res2.data || []
      } catch (e) {
        console.error(e)
      }
    },
    // 绑定管理员模板
    showBindDialog(row) {
      this.visibleSingleBindDialog = true
      this.$nextTick(() => {
        this.$refs.dialogSingleBindRef.id = row.id
        this.$refs.dialogSingleBindRef.parentData = cloneDeep(row)
      })
    },
    // 场地排期模板
    showScheduleDialog(row) {
      this.visibleScheduleDialog = true
      this.$nextTick(() => {
        this.$refs.dialogScheduleRef.parentData = cloneDeep(row)
      })
    }
  }
}
</script>
<style lang="scss">
.badminton-court {
  .header-button {
    .descriptions-item__label {
      width: 100px;
    }
  }
}
</style>
