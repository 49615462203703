import request from '@/service/request'

/* 羽毛球场 时间表，分页
* @params
* */
export const pageBadmintonScheduleApi = (params) => {
  return request.post('/stu/badmintonRoomSchedule/page', params)
}

/* 羽毛球场 时间表，list 获取所有权限 管理员和开发人员
* 接口地址准确 因为后端颠颠的，乱来 ，导致羽毛球几个page接口命名不规范
* @params
* */
export const cellBadmintonScheduleApi = (params = {}) => {
  return request.post('/stu/badmintonRoomSchedule/cell', params)
}

/* 羽毛球场 时间表，根据管理员权限获取
* @params
* */
export const cellBadmintonScheduleByManagerApi = (params = {}) => {
  return request.post('/stu/badmintonRoomSchedule/cellManager', params)
}

/* 羽毛球场 时间表，根据id获取
* @params
* */
export const getBadmintonScheduleByIdApi = (id) => {
  return request.post(`/stu/badmintonRoomSchedule/get/${id}`)
}
/* 羽毛球场 时间表，删除
* @params
* */
export const delBadmintonScheduleByIdApi = (id) => {
  return request.post(`/stu/badmintonRoomSchedule/del/${id}`)
}

/* 羽毛球场 时间表，保存
* @params
* */
export const batchBindBadmintonScheduleByUserIdApi = (params) => {
  return request.post('/stu/badmintonRoomSchedule/manager/saveBatch', params)
}

/* 羽毛球场 时间表，管理员list
* @params
* */
export const listBadmintonAdminApi = (params = {}) => {
  return request.post('/stu/badmintonRoomSchedule/manager/list', params)
}

/* 球场模板 type=1 */

/* 羽毛球场 时间表，模板记录
* @params
* */
export const listBadmintonModelApi = (params = {}) => {
  return request.post('/stu/badmintonRoomSchedule/list', params)
}

/* 羽毛球场 时间表，删除模板
* @params
* */
export const delBadmintonModelApi = (ids) => {
  return request.post(`/stu/badmintonRoomSchedule/del/${ids}`)
}

/* 羽毛球场 时间表，(多对多)的模板保存
* @params
* */
export const saveBadmintonModelApi = (params) => {
  return request.post('/stu/badmintonRoomSchedule/saveBatchByRoomIdsAndWeeks', params)
}

/* 羽毛球场 时间表，编辑当日 启用/禁用后的模板保存
* @params
* */
export const editBatchBadmintonOffFlagApi = (params) => {
  return request.post('/stu/badmintonRoomSchedule/saveCustomBatchByRoomId', params)
}
