// 远程搜索 学生或教师 mixins
import { getUserAll, listDormUserApi } from '@/api/system/user-api'
import { getStudentListQuery } from '@/api/student'

export default {
  data() {
    return {
      isDorm: false, // 用于判断是否为搜索住宿的教职工
      userTypeList: [], // 用户类型 用于搜索 用户或学生
      userSelectDisabled: false // 选中后禁止编辑用户
    }
  },
  mounted() {
    this.$http.getParamListByKey('searchUserType', this, 'userTypeList')
  },
  methods: {
    // 远程搜索用户
    async querySearchAsync(queryString, cb) {
      let result = []
      /* 判断是否请求接口 为false 时请求接口
      * 默认为 true ,因为为false+queryString===''时加载所有的几万条学生数据
      * */
      let _correctQuery = false
      if (queryString) {
        if (!isNaN(queryString)) {
          // 支持学号(number)搜索,但是学号必须填入6位数以上
          if (queryString.length > 5) {
            _correctQuery = true
          }
        } else if (queryString.length >= 1) {
          // 为string,且关键字大于一个字才能开始搜索,降低服务器压力
          _correctQuery = true
        }
      }
      if (_correctQuery) {
        // 3-管理员
        if (Number(this.formData.userType) === 3||!this.formData.userType) {
          try {
            let res
            if (this.isDorm) {
              res = await listDormUserApi({ keyword: queryString })
              res.data.map((item) => {
                item.value = item.realName
              })
              result = res.data
            } else {
              res = await getUserAll({ realName: queryString })
              res.data.map((item) => {
                item.value = item.realName
              })
              result = res.data
            }
            cb(result)
          } catch (e) {
            console.error(e)
          }
        }
        // 2-学生
        if (Number(this.formData.userType) === 2) {
          getStudentListQuery({
            keyword: queryString,
            collegeId: this.$store.state.login.userInfo.collegeId || null
          }).then((res) => {
            res.data.map((item) => {
              item.value = `${item.examineNo}-${item.stuName}(${item.idNumber})`
            })
            result = res.data
            cb(result)
          })
        }
      } else {
        cb(result)
      }
    },
    // 清空选中
    clearUserSelect() {
      this.formData.userId = null
      this.formData.userName = null
      this.formData.value = null
      this.userSelectDisabled = false
    }
  }
}
